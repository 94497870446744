<template>
  <div>
    <r-header :title="'缴纳水费'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <div class="water-box">
      <van-card
        :title="item.dianpuname"
        :thumb="'/apis' + item.dianpuimg"
      >
        <template #price>
          <p class="price-text">
            <span class="mr-10">{{ item.feistatus === '1' ? '欠费' : '余额' }}</span>
            <span v-if="item.feistatus === '1'">¥ <span class="money">{{ fmoney(item.amt, 2) }}</span></span>
            <span v-else>¥ <span class="money">{{ fmoney(item.rechargeamt, 2) }}</span></span>
          </p>
        </template>
      </van-card>
      <van-form @submit="onSubmit">
        <van-cell-group>
          <!-- <van-field label="开始码" :value="item.startnum" readonly />
          <van-field label="结束码" :value="item.endnum" readonly />
          <van-field v-model="money" type="number" label="缴费金额" placeholder="请输入缴费金额" /> -->
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">充值</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  name: 'WaterPay',
  components: {
    RHeader
  },
  data () {
    return {
      money: null,
      item: {}
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    backPage () {
      this.$router.push({
        name: 'Water'
      })
    },
    initData () {
      const id = this.$route.query.id
      this.$axios.post('/gw/shuidianinfo', { id }).then(res => {
        console.log(res)
        const { data, code } = res.data
        if (code === 200) {
          this.item = { ...data }
        }
      })
    },
    onSubmit () {
      this.disabled = true
      if (this.disabled) {
        const redirect = 'http://www.whwwsc.com/movie/h5pay/'
        const orderId = `4*${this.item.id}`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx63c208bb195251ca&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_userinfo&state=${orderId}#wechat_redirect`
      }
    }
  }
}
</script>
<style lang="less" scoped>
.water-box{
  margin-top: 1rem;
}
.price-text{
  font-size: 14px;
  .money{
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
